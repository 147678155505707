import React from 'react'
import Error from '../components/Error'
import Layout from '../templates/layout'

const error = () => {
  return (
    <Layout >
      <Error />
    </Layout>
  )
}
export const Head = () => <title>Błąd systemu | Zapłatomat</title>;

export default error
