import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import BasePageContent from './utils/BasePageContent';
import { connect } from 'react-redux';

const mapStateToProps = state => ({ error: state.generalError });

const Error = ({ error }) => (
  <BasePageContent>
    <Alert severity='error'>
      <AlertTitle>Błąd</AlertTitle>
      {error?.reason}
    </Alert>
  </BasePageContent>
);

export default connect(mapStateToProps)(Error);
